<template>
  <div>
    <table-persons
      @create="_goToCreatePage"
      @edit="(data) => _goToEditPage(data.id)"
      @changeSelectRoles="_changeSelectUsersRoles"
      @removeItem="removeUser"
      @removeMultiplyItem="removeMultiplyUser"
      @updatePage="_updatePage"
      @updateSearchText="_findPersonsByText"
      :roles="roles"
      :persons="getUsersList"
      :totalDesserts="table.totalDesserts"
      :totalPages="table.totalPages"
      :currentPage="table.page"
      :loading="userLoading"
      :headers="headers"
      ref="tableUsers"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TablePersons from '@components/Admin/TablePersons/TablePersons.vue';
import HelpersErrors from '@helpers/errors';
import userMixin from '@/mixins/Users/userMixin';
import tablePersons from '@/mixins/Table/tablePersons';

export default {
  name: 'ArchiveUsers',
  components: {
    TablePersons,
  },
  mixins: [
    userMixin,
    tablePersons,
  ],
  props: {},
  computed: {
    ...mapGetters({
      userLoading: 'users/isLoading',
      users: 'users/getArchiveUsers',
      roles: 'roles/getRoles',
    }),
    getUsersList() {
      return this.users.data;
    },
  },
  data() {
    return {
      headers: [
        { text: window.app.$t('message.admin.id'), value: 'id', sortable: false },
        { text: '', value: 'checkbox', sortable: false },
        { text: window.app.$t('message.admin.role'), value: 'roles', sortable: false },
        { text: window.app.$t('message.admin.name'), value: 'full_name', sortable: false },
        { text: window.app.$t('message.admin.email'), value: 'email', sortable: false },
        { text: window.app.$t('message.admin.phone'), value: 'phone_number', sortable: false },
        { text: window.app.$t('message.admin.personal_code'), value: 'e_klass_personal_code', sortable: false },
        { text: window.app.$t('message.admin.actions'), value: 'actions', sortable: false },
      ],
      tableContainer: null,
      table: {
        page: 1,
        search: null,
        role_id: null,
        totalDesserts: 0,
        totalPages: 0,
      },
    };
  },
  created() {
    this.getDataTable();
    this._addEventListener();
  },
  mounted() {
    this.tableContainer = this.$refs.tableUsers;
  },
  methods: {
    getDataTable(params) {
      return this.$api.users.getArchiveUsers(params).then(() => {
        this.table.totalDesserts = this.users.meta.pagination.total;
        this.table.totalPages = this.users.meta.pagination.total_pages;
        this.table.page = this.users.meta.pagination.current_page;
      });
    },
    removeUser(idUser) {
      this._removeUser(idUser).catch(async (error) => {
        HelpersErrors.showErrors(error.response.data.errors);
      });
    },
    removeMultiplyUser(idsArray) {
      this._removeMultiplyUser(idsArray).catch(async (error) => {
        HelpersErrors.showErrors(error.response.data.errors);
      });
    },
  },
  beforeDestroy() {
    this._removeEventListener();
  },
};
</script>
