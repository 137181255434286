const configTable = {
  search: null,
  role_id: null,
  totalDesserts: 0,
  totalPages: 0,
};

export default {
  data: () => ({}),
  computed: {},
  methods: {
    _addEventListener() {
      this.$events.$on('fetchUsers', () => {
        this._updateTable(this.table);
      });
    },
    _removeEventListener() {
      this.$events.$off('fetchUsers');
    },
    _goToCreatePage() {
      this.$router.push('/admin/users/create');
    },
    _goToEditPage(id) {
      this.$router.push(`/admin/users/${id}/edit`);
    },
    async _fetchAllUsers() {
      this.$events.$emit('fetchUsers');
    },
    _resetUserParams() {
      this.table = { ...configTable };
    },
    // eslint-disable-next-line consistent-return
    async _removeUser(idUser) {
      const { tableUsers } = this.$refs;
      const confirm = await this.$root.$confirm.open(
        this.$t('message.admin.delete'),
        this.$t('message.admin.are_you_sure_you_want_to_delete_a_user'),
      );

      if (confirm) {
        return new Promise((resolve, reject) => {
          this.$api.users.removeUser(idUser).then(async () => {
            await this._fetchAllUsers();
            tableUsers.selected = [];
          }).catch(async (err) => {
            reject(err);
          });
        });
      }
    },
    async _removeMultiplyUser(idsArray) {
      if (!idsArray.length) {
        this._showErrorNotify(this.$t('message.admin.please_select_users'));
        return;
      }

      const { tableUsers } = this.$refs;
      const confirm = await this.$root.$confirm.open(
        this.$t('message.admin.delete'),
        this.$t('message.admin.are_you_sure_you_want_to_delete_a_users'),
      );

      if (confirm) {
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
          this.$api.users.removeMultiplyUser({ ids: idsArray }).then(async () => {
            await this._fetchAllUsers();
            tableUsers.selected = [];
          }).catch(async (err) => {
            reject(err);
          });
        });
      }
    },
  },
};
