<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-container>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            class="tab_theme-default"
          >
            <v-tab :disabled="userLoading"> {{ $t('message.admin.user') }} </v-tab>
            <v-tab :disabled="userLoading"> {{ $t('message.admin.archive') }} </v-tab>
          </v-tabs>
        </v-container>
        <v-tabs-items v-model="tab">
          <v-tab-item :transition="false" :reverse-transition="false">
            <ActiveUser />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <ArchiveUser />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import ActiveUser from '@components/Admin/ActiveUser/ActiveUser.vue';
import ArchiveUser from '@components/Admin/ArchiveUser/ArchiveUser.vue';

export default {
  name: 'Users',
  components: {
    ActiveUser,
    ArchiveUser,
  },
  props: {},
  computed: {
    ...mapGetters({
      userLoading: 'users/isLoading',
    }),
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {},
};
</script>
